<template>
  <v-content>
    <v-container fluid grid-list-lg>
      <v-layout wrap justify-center row fill-height>
        <v-flex xs2 sm2>
          <v-card v-if="_.size(inventory) > 0">
            <v-toolbar class="elevation-0" color="secondary" dark dense>
              <v-toolbar-title class="subheading font-weight-regular">
                Inventario Nº {{ inventory.id }}
              </v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              Inventario realizado por <br />
              {{ inventory.user_name }}
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                class="accent"
                :loading="loadingAdjust"
                :disabled="loadingAdjust || inventory.state == 2"
                flat
                @click="dialogInventory = true"
              >
                Ajustar
              </v-btn>
              <v-btn
                class="success"
                :loading="loadingExcel"
                :disabled="loadingExcel"
                flat
                @click="exportExcel"
              >
                Excel
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-flex>
        <v-flex xs10 sm10>
          <v-card>
            <v-toolbar class="elevation-0" color="secondary" dark dense>
              <v-toolbar-title class="subheading font-weight-regular">
                Inventarios
              </v-toolbar-title>
            </v-toolbar>
            <template>
              <v-data-table
                :disable-initial-sort="true"
                :pagination.sync="pagination"
                :rows-per-page-items="rowsPerPage"
                :headers="headers"
                :items="inventories"
                :loading="loading"
                class="elevation-0"
              >
                <v-progress-linear
                  slot="progress"
                  color="accent"
                  indeterminate
                ></v-progress-linear>
                <template slot="items" slot-scope="props">
                  <tr>
                    <td
                      class="text-xs-left"
                      v-text="props.item.insumo.type"
                    ></td>
                    <td
                      class="text-xs-left"
                      v-text="props.item.insumo.name"
                    ></td>
                    <td
                      class="text-xs-right"
                      v-text="props.item.insumo.codigo"
                    ></td>
                    <td class="text-xs-right">
                      {{ `${props.item.stock} ${props.item.insumo.um}` }}
                    </td>
                    <td class="text-xs-right">
                      {{ `${props.item.total} ${props.item.insumo.um}` }}
                    </td>
                    <td class="text-xs-right">
                      <v-chip
                        v-if="props.item.total > props.item.stock"
                        color="warning"
                      >
                        Sobre Stock
                      </v-chip>
                      <v-chip
                        v-else-if="props.item.total < props.item.stock"
                        text-color="white"
                        color="error"
                      >
                        Falta Stock
                      </v-chip>
                      <v-chip
                        v-else-if="props.item.total === props.item.stock"
                        text-color="white"
                        color="success"
                      >
                        Perfeccion
                      </v-chip>
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </template>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>

    <v-dialog v-model="dialogInventory" persistent max-width="290">
      <v-card>
        <v-card-title class="headline">Ajustar inventario</v-card-title>
        <v-card-text>
          Al continuar se ajustara el stock de los productos inventariados
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="default darken-1" flat @click="dialogInventory = false">
            Volver
          </v-btn>
          <v-btn color="accent" depressed @click="adjustInventary">
            Continuar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-content>
</template>

<script>
import { GET_INVENTORIES, INVENTORIES } from '@/config'

export default {
  name: 'InventoryDetail',
  props: ['id'],
  data() {
    return {
      title: 'Inventarios detalle',
      inventories: [],
      inventory: {},
      pagination: { rowsPerPage: 50 },
      rowsPerPage: [25, 50, 100, 200, { text: 'Todos', value: -1 }],
      loading: false,
      loadingExcel: false,
      loadingAdjust: false,
      dialogInventory: false,
      headers: [
        {
          text: 'Tipo',
          align: 'left',
          sortable: false,
          value: 'name'
        },
        {
          text: 'Nombre',
          align: 'left',
          sortable: false,
          value: 'name'
        },
        {
          text: 'Codigo',
          align: 'right',
          sortable: false,
          value: 'name'
        },
        {
          text: 'Stock Actual',
          align: 'right',
          sortable: false,
          value: 'user'
        },
        {
          text: 'Stock Inventario',
          align: 'right',
          sortable: false,
          value: 'active'
        },
        {
          text: '',
          align: 'right',
          sortable: false,
          value: 'active'
        }
      ]
    }
  },
  mounted() {
    this.getInventory()
    this.filterFrom = this.$moment()
      .subtract(1, 'months')
      .format('YYYY-MM-DD')
    this.filterEnd = this.$moment().format('YYYY-MM-DD')
    this.$store.dispatch('updateTitle', 'Detalle inventarios')
  },
  methods: {
    async getInventory() {
      this.loading = true
      try {
        const res = await this.$http.get(`${INVENTORIES}/${this.id}`)
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Inventario Actualizado'
        })
        this.inventories = res.data.inventories
        this.inventory = res.data.inventory
        this.$store.dispatch('updateTitle', `${this.inventory.description}`)
        this.loading = false
      } catch (error) {
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Ocurrio un error, intente nuevamente'
        })
      }
    },
    async adjustInventary() {
      this.dialogInventory = false
      this.loadingAdjust = true
      try {
        await this.$http.put(`${INVENTORIES}/${this.id}/ajuste`, {
          id: this.id
        })
        this.loadingAdjust = false
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Inventariado ajustado'
        })
      } catch (error) {
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Ocurrio un error, intente nuevamente'
        })
      }
    },
    exportExcel() {
      this.loadingExcel = true
      this.$http({
        method: 'post',
        url: GET_INVENTORIES,
        params: {
          p: 'excelInventoriesDetail',
          id: this.id
        }
      }).then(res => {
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Excel generado'
        })
        const { url } = res.data.json
        window.location.href = url
        this.loadingExcel = false
      })
    }
  }
}
</script>
